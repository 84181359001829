import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

import style from './style.module.css'
import globalStyle from '../../style.module.css'
import routeConfig from '../../routes'
import { optionsColor } from '../step1/fieldOptions'

const options = [
    {
        path: "/inleiding",
        name: "Benoeming"
    }, {
        path:"/kaart-aanmaken", 
        name: "Jouw gegevens"
    }, {
        path: "/kaart-delen", 
        name: "Delen"
    }
]

const PaginationDesktop = ({filledIn}) => {
    const location = useLocation();
    const { primary_color } = useSelector(state => state.form)
    const navigate = useNavigate();

    const handleNavigation = (index) => {
        navigate(routeConfig[index].path)
    }

    return (
        <div className={` ${style.pagination}`}>
            <ul className={style.list}>

                {
                    options.map((item, index) => {

                        return (
                            <li key={`pagination-item-${index}`} className={`${style.listItem} ${location.pathname === item.path ? style.listItemActive : ''}`}>
                                <button disabled={filledIn ? false : true} className={style.listItemButton} onClick={() => { handleNavigation(index) }}>
                                    {item.name}
                                    <span></span>
                                </button>
                            </li>
                        )
                    })
                }
                
            </ul>
        </div>
    )
}

export default PaginationDesktop


