import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './size.css'
import style from './style.module.css'
import BannerSquare from '../../templates/banner_square'
import fitty from 'fitty'

const Preview = ({ selectorHidden = false }) => {

    const { media_size, rotated } = useSelector(state => state.form)
    const dispatch = useDispatch()
    
    const handleRotate = () => {
        dispatch({ type: 'SET_FIELD', field: 'rotated', payload: !rotated })
    }

    useEffect(() => {
        setTimeout(()=> {
            [...document.querySelectorAll('.size-square')].forEach(item => { 
                fitty(item.querySelector('.name'), {maxSize: item.clientHeight/1.2});
            })
        })
    })
    setTimeout(()=> {
        [...document.querySelectorAll('.size-square')].forEach(item => { 
            fitty(item.querySelector('.name'), {maxSize: item.clientHeight/1.2});
        })
    }, 100);

    return (
        <>
            <div className={`${style.preview} preview-container`}>
                {
                    {
                        'banner_square': <BannerSquare rotated={rotated} />,
                    }[media_size]
                }
            </div>
            <div className={`${style.preview} preview-render`} id="preview-render">
                <div className={`${style.previewRenderInner} preview-render-inner`}>
                    {
                        {
                            'banner_square': <BannerSquare rotated={rotated} render={true} />,
                        }[media_size]
                    }
                </div>
            </div>
            <div className={`${style.preview} preview-render-small`} id="preview-render-small">
                {
                    {
                        'banner_square': <BannerSquare rotated={rotated} render={true} size={'760px'} />,
                    }[media_size]
                }
            </div>
        </>
    )
}

export default Preview


