import download from 'downloadjs'
import * as htmlToImage from 'html-to-image';
import html2canvas from 'html2canvas'

export const processDownload = async (node, type, text, onSite, color, FD2) => {
    let image, imageLandscape;
    return new Promise((resolve, reject) => {
        setTimeout(()=> {
            html2canvas(document.getElementById("preview-render-small").querySelector('.posterFront'), { letterRendering: 1 }).then(dataUrl => {
                image = dataUrl.toDataURL('image/png');
                html2canvas(document.getElementById("preview-render").querySelector('.preview-render-inner'), { letterRendering: 1 }).then(dataUrl2 => {
                    imageLandscape = dataUrl2.toDataURL('image/png')
                    FD2.append('image', image)
                    FD2.append('social_image', imageLandscape)
                    FD2.append('text', `${text}`)
                    FD2.append('show', onSite)
                    FD2.append('colour', `${color}`)
                    fetch(`${process.env.REACT_APP_API_ENDPONT}/upload-2023`, {
                        method: 'POST',
                        body: FD2
                    }).then(r2 => r2.json()).then(r2 => { let data = {image: image, data: r2};  resolve(data)}).catch(e2 => {reject(e2) })
                });
            });
        }, 500)
    });

}

export function downloadFile(dataUrl, filename = '') {
    download(dataUrl, filename);
}



export function forceDownload(data, name = 'file') {
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);
    anchor.style = 'display: none';
  
    anchor.href = window.URL.createObjectURL(data);
    anchor.download = name;
    anchor.click();
}
  

  

export const debounce = (callback, wait, immediate = false) => {
    let timeout = null 
    
    return function() {
      const callNow = immediate && !timeout
      const next = () => callback.apply(this, arguments)
      
      clearTimeout(timeout)
      timeout = setTimeout(next, wait)
  
      if (callNow) {
        next()
      }
    }
}

export const getRetailerSentence = (list = [], stripTags = false) => {

    if(list.length <= 0) return `Donate to our club by using the Woolsocks app`;

    const retailers = list.map(r => r.value)
    const text = retailers.reduce((text, value, i, array) => text + (i < array.length - 1 ? '</strong>, <strong>' : '</strong> or <strong>') + value);
    
    let sentence = `Shop at <strong>${text}</strong> and donate to our club by using the Woolsocks app`
    if(stripTags) {
        sentence = sentence.replace(/(<([^>]+)>)/gi, "");
    }

    return sentence
}