import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Preview from '../preview'
import Button from '../../components/button'
import OrgNameInput from '../../components/orgNameInput'
import OrgLocationInput from '../../components/orgLocationInput'
import OrgFunctionInput from '../../components/orgFunctionInput'
import Checkbox from '../../components/checkbox'
import ImageInput from '../../components/imageInput'
import NameInput from '../../components/nameInput'
import SurNameInput from '../../components/surNameInput'
import ContentInput from '../../components/contentInput'
import ExplainInput from '../../components/explainInput'
import Pagination from '../pagination'
import { optionsColor } from './fieldOptions'
import { useNavigate, Link } from 'react-router-dom'
import routeConfig from '../../routes'

import globalStyle from '../../style.module.css'

import Uppy from '@uppy/core'
import { processDownload } from '../../utilities'

const Step = () => {

    const { media_size, forename, txt, primary_color, surname, explanation, organization_name, organization_location, organization_function, avatar_image } = useSelector(state => state.form)

    const dispatch = useDispatch()
    const handleChange = (field, payload) => {
        dispatch({ type: 'SET_FIELD', field, payload })
    }

    const navigate = useNavigate();
    
    const uppy = useMemo(() => {
        return new Uppy({
            restrictions: {
                maxNumberOfFiles: 5,
                allowedFileTypes: ['image/jpeg', 'image/png']
            }
        })
    }, [])

    // Number of retailers
    const [current, setCurrent] = useState(false)
    const [popupOpen, setPopupOpen] = useState(false)
    const [nameError, setNameError] = useState(false)
    const [forenameError, setForeNameError] = useState(false)
    const [surnameError, setSurNameError] = useState(false)
    const [contentError, setContentError] = useState(false)
    const mediaRender = useRef(null)
    
    const handleNameChange = (event) => {
        handleChange('forename', event.currentTarget.value)
    }

    // Update current screen
    useEffect(() => {
        dispatch({ type: 'SET_FIELD', field: 'current_step', payload: 0 })
    }, [dispatch])


    useEffect(() => {
        dispatch({ type: 'SET_FIELD', field: 'show_onboarding', payload: false })
    }, [dispatch])
   
    const updateUppy = useCallback(() => {
        return dispatch({ type: 'SET_FIELD', field: 'retailers', payload: uppy.getFiles()})
    }, [dispatch, uppy])

    useEffect(() => {
        uppy.on('files-added', updateUppy)
        uppy.on('file-removed', updateUppy)
    }, [uppy, updateUppy])

    const handleGdpr = () => {
        setCurrent(!current)
    }
    const filledIn = forename.length && surname.length && organization_name.length && organization_function.length && txt.length && current
 
    const handleNavigate = () => {
        if(filledIn) {
            navigate(routeConfig[1].path)
        } else {
            setNameError(forename.length >= 2 ? false : true)
            setForeNameError(forename.length >= 2 ? false : true)
            setSurNameError(surname.length >= 2 ? false : true)
            setContentError(txt.length >= 15 ? false : true)
        }
    }
    
    return (
        <div className={globalStyle.container}>
            <div className={globalStyle.generatorCols}>
                <div className={`${globalStyle.generatorCol} ${globalStyle.generatorColAlt}`}>
                    <div ref={mediaRender} className={`${globalStyle.preview}`}>
                        <Preview />
                    </div>
                </div>
                <div className={globalStyle.generatorCol}>
                    <div className={globalStyle.form}>
                        <Pagination filledIn={filledIn} />
                        <div className={globalStyle.content}>
                            <h2 className={globalStyle.title}>Vul hier de gegevens in van jouw Grote Mens 2023</h2>
                            <p>Heb je een Groot Mens gevonden in jouw gemeente, die je wilt benoemen voor de Grote Mensen lijst van 2023? Schrijf dan hieronder een tekst over wat die persoon Groot maakt! Hulp nodig? <a href={"https://meergrotemensen.nl/toolkit-uitleg"} target="_blank" style={{color: '#ffffff'}}><b>Bekijk de instructie</b></a>.</p>
                        </div>
                        <div className={globalStyle.formRow}>
                            <span className={globalStyle.subtitle} style={{display: 'flex', justifyContent: 'space-between'}}><span style={{flex: 1, paddingRight: '10px'}}>Voornaam en Achternaam van jouw Grote Mens&nbsp;*</span> </span>
                            <div className={globalStyle.formCols}>
                                <div>
                                    {
                                        forenameError && (
                                            <span className={globalStyle.error}>Vul minimaal 2 tekens in.</span>
                                        )
                                    }
                                    <NameInput
                                        handleChange={handleNameChange}
                                        value={forename}
                                    />
                                </div>
                                <div>
                                    {
                                        surnameError && (
                                            <span className={globalStyle.error}>Vul minimaal 2 tekens in.</span>
                                        )
                                    }
                                    <SurNameInput
                                        value={surname}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={globalStyle.formRow}>
                        <span className={globalStyle.subtitle} style={{display: 'flex', justifyContent: 'space-between'}}><span style={{flex: 1, paddingRight: '10px'}}>Werkplek&nbsp;*</span> </span>
                            <div className={globalStyle.formCols}>
                                <div>
                                    {
                                        nameError && (
                                            <span className={globalStyle.error}>Vul minimaal 2 tekens in.</span>
                                        )
                                    }
                                    <OrgNameInput
                                        value={organization_name}
                                    />
                                </div>
                                <div>
                                    {
                                        nameError && (
                                            <span className={globalStyle.error}>Vul minimaal 2 tekens in.</span>
                                        )
                                    }
                                    <OrgLocationInput
                                        value={organization_location}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={globalStyle.formRow}>
                            <span className={globalStyle.subtitle} style={{display: 'flex', justifyContent: 'space-between'}}><span style={{flex: 1, paddingRight: '10px'}}>Functie&nbsp;*</span> </span>
                            {
                                nameError && (
                                    <span className={globalStyle.error}>Vul minimaal 2 tekens in.</span>
                                )
                            }
                            <OrgFunctionInput
                                value={organization_function}
                            />
                        </div>
                        <div className={globalStyle.formRow}>
                            <span className={globalStyle.subtitle} style={{display: 'flex', justifyContent: 'space-between'}}><span style={{flex: 1, paddingRight: '10px'}}>Wat maakt hem/haar/die een Groot Mens?&nbsp;*</span> <span style={{width: 'width: 90px', textAlign: 'right'}}>{txt.length} / 450 </span></span>
                            {
                                contentError && (
                                    <span className={globalStyle.error}>Vul minimaal 15 tekens in.</span>
                                )
                            }
                            <ContentInput
                                value={txt}
                            />
                        </div>
                        <div className={globalStyle.formRow}>
                            <span className={globalStyle.subtitle} style={{display: 'flex', justifyContent: 'space-between'}}><span style={{flex: 1, paddingRight: '10px'}}>Wat wil je dat JOGG nog meer weet over jouw Grote Mens?</span>{explanation.length}  / 300 </span>
                            {
                                nameError && (
                                    <span className={globalStyle.error}>Vul minimaal 15 tekens in.</span>
                                )
                            }
                            <ExplainInput
                                value={explanation}
                            />
                        </div>
                        <div className={globalStyle.formRow}>
                            <span className={globalStyle.subtitle} style={{display: 'flex', justifyContent: 'space-between'}}><span style={{flex: 1, paddingRight: '10px'}}>Upload een foto van jouw Grote Mens</span></span>
                            <ImageInput
                                value={avatar_image}
                            />
                        </div>
                        <div className={globalStyle.formRow}>
                            <div style={{padding: '1rem 0px 2rem', marginTop: '-2rem', fontSize: '14px'}}>* Deze velden zijn verplicht.</div>
                        </div>
                        <div className={globalStyle.formRow}>
                            <Checkbox
                                identifier="gdpr"
                                label="De genomineerde geeft toestemming om te worden opgenomen in de Grote Mensen van 2023 lijst op het moment dat ik op ‘Benoem Groot Mens’ klik."
                                checked={current}
                                onChange={handleGdpr}
                                color={optionsColor.filter(e=>e.value === primary_color).map(e=>e.color)[0]}
                            />
                        </div>
                        <div className={globalStyle.flexWrapper}>
                            <div></div>
                            <div>
                                <Button disabled={filledIn ? false : true} onClick={handleNavigate} text="ga verder" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>
    )
}

export default Step