import React from 'react'
import style from './style.module.css'

const checkbox = ({checked, identifier, label, onChange, color}) => {
    return (
        <div className={style.checkbox}>
            <input checked={checked} id={identifier} type="checkbox" onChange={onChange} />
            <label htmlFor={identifier}>
                <span className={style.checkboxLabel}>{label}</span>
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m5.5 12.5-.774.633a1 1 0 0 0 1.595-.062L5.5 12.5ZM.226 7.633l4.5 5.5 1.548-1.266-4.5-5.5L.226 7.633Zm6.095 5.438 8-11.5L12.679.43l-8 11.5 1.642 1.142Z" fill="#91C662"/></svg>
            </label>
        </div>
    )    
}

export default checkbox