import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../../components/button'
import BackButton from '../../components/backButton'
import ButtonAlt from '../../components/button/buttonAlt'
import Preview from '../preview'
import DirectorNameInput from '../../components/directorNameInput'
import DirectorSurNameInput from '../../components/DirectorSurNameInput'
import EmailInput from '../../components/emailInput'
import MunicipalInput from '../../components/municipalInput'
import Pagination from '../pagination'
import { optionsColor } from './fieldOptions'
import { useNavigate } from 'react-router-dom'
import routeConfig from '../../routes'

import globalStyle from '../../style.module.css'

import Uppy from '@uppy/core'
import { processDownload } from '../../utilities'
import validator from 'validator'

const Step = () => {

    const { media_size, director_forename, content, primary_color, director_surname, director_email, director_municipality, forename,
        surname,
        organization_name,
        organization_location,
        organization_function,
        txt,
        explanation,
        image,
        social_image,
        avatar_image,
        avatar_extension,
        show,
     } = useSelector(state => state.form)

    const dispatch = useDispatch()
    const handleChange = (field, payload) => {
        dispatch({ type: 'SET_FIELD', field, payload })
    }

    const navigate = useNavigate();
    
    const uppy = useMemo(() => {
        return new Uppy({
            restrictions: {
                maxNumberOfFiles: 5,
                allowedFileTypes: ['image/jpeg', 'image/png']
            }
        })
    }, [])


    // Number of retailers
    const [current, setCurrent] = useState(false)
    const [popupOpen, setPopupOpen] = useState(false)
    // const [nameError, setNameError] = useState(false)
    const [contentError, setContentError] = useState(false)
    const mediaRender = useRef(null)

    const handleNameChange = (event) => {
        handleChange('director_forename', event.currentTarget.value)
    }
    const handleContentChange = (event) => {
        handleChange('content', event.currentTarget.value)
    }

    // Update current screen
    useEffect(() => {
        dispatch({ type: 'SET_FIELD', field: 'current_step', payload: 0 })
    }, [dispatch])

    useEffect(() => {
        dispatch({ type: 'SET_FIELD', field: 'show_onboarding', payload: false })
    }, [dispatch])
   

    const updateUppy = useCallback(() => {
        return dispatch({ type: 'SET_FIELD', field: 'retailers', payload: uppy.getFiles()})
    }, [dispatch, uppy])

    useEffect(() => {
        uppy.on('files-added', updateUppy)
        uppy.on('file-removed', updateUppy)
    }, [uppy, updateUppy])

    const closePopup = () => {
        setPopupOpen(false);
    }
    const openPopup = () => {
        if(director_forename.length >= 2 && content.length >= 15) {
            setPopupOpen(true);
            dispatch({ type: 'SET_FIELD', field: 'rotated', payload: false })
        } else {
            // setNameError(director_forename.length >= 2 ? false : true)
            setContentError(content.length >= 15 ? false : true)
        }
    }
    const handleGenerate = () => {
        
    }

    const toDataUrl = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob); 
          reader.onloadend = () => {
            const base64data = reader.result;   
            resolve(base64data);
          }
        });
    }
    
    const handleDone = () => {
        validateEmail()
    }
    const [emailError, setEmailError] = useState('')
    const validateEmail = () => {
        var email = director_email
        if (validator.isEmail(email)) {
            const FD2 = new FormData()

            FD2.append('forename', forename)
            FD2.append('surname', surname)
            FD2.append('organization_name', organization_name)
            FD2.append('organization_location', organization_location)
            FD2.append('organization_function', organization_function)
            FD2.append('txt', txt)
            FD2.append('explanation', explanation)
            FD2.append('avatar_image', avatar_image)
            FD2.append('avatar_extension', avatar_extension)
            FD2.append('director_forename', director_forename)
            FD2.append('director_surname', director_surname)
            FD2.append('director_email', director_email)
            FD2.append('director_municipality', director_municipality)
            
    
            processDownload(document.getElementById('posterFront'), media_size, txt, current, primary_color, FD2).then((data)=> {
                dispatch({ type: 'SET_FIELD', field: 'download', payload: data.image})
                dispatch({ type: 'SET_FIELD', field: 'shareUrl', payload: data.data.url})
                setTimeout(()=> {
                    navigate(routeConfig[2].path)
                }, 100)
            })
        } else {
            setEmailError('Vul aub een geldig emailadres in')
        }
    }

    const filledIn = director_forename.length && director_surname.length && director_email.length && director_municipality.length
    
    return (
        <div className={globalStyle.container}>
            <div className={globalStyle.generatorCols}>
                <div className={`${globalStyle.generatorCol} ${globalStyle.generatorColAlt}`}>
                    <div ref={mediaRender} className={`${globalStyle.preview}`}>
                        <Preview />
                    </div>
                </div>
                <div className={globalStyle.generatorCol}>
                    <div className={globalStyle.form}>
                        <Pagination />
                        <div className={globalStyle.content}>
                            <h2 className={globalStyle.title}>Vul hier jouw gegevens in als JOGG-regisseur</h2>
                            <p>Dan kunnen we jou benaderen voor eventuele tekstuele aanpassingen.</p>
                        </div>
                        <span className={globalStyle.subtitle} style={{display: 'flex', justifyContent: 'space-between'}}><span style={{flex: 1, paddingRight: '10px'}}>Voor- en achternaam&nbsp;*</span> </span>
                        <div className={globalStyle.formRow}>
                            <div className={globalStyle.formCols}>
                                <div>
                                    <DirectorNameInput
                                        value={director_forename}
                                    />
                                </div>
                                <div>
                                    <DirectorSurNameInput
                                        value={director_surname}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={globalStyle.formRow}>
                            <span className={globalStyle.subtitle} style={{display: 'flex', justifyContent: 'space-between'}}><span style={{flex: 1, paddingRight: '10px'}}>Emailadres&nbsp;*</span> </span>
                            <EmailInput
                                value={director_email}
                            />
                            {
                                emailError && (
                                    <div style={{padding: '1rem', paddingBottom: '0.5rem', paddingTop: '0.5rem', fontSize: '14px', backgroundColor: '#cf1919', marginTop: '0.5rem' }}>{emailError}</div>
                                )
                            }
                        </div>
                        <div className={globalStyle.formRow}>
                            <span className={globalStyle.subtitle} style={{display: 'flex', justifyContent: 'space-between'}}><span style={{flex: 1, paddingRight: '10px'}}>Gemeente&nbsp;*</span> </span>
                            <MunicipalInput
                                value={director_municipality}
                            />
                        </div>
                        <div className={globalStyle.formRow}>
                            <div style={{padding: '1rem 0px 2rem', fontSize: '14px'}}>* Deze velden zijn verplicht.</div>
                        </div>
                        <div className={`${globalStyle.flexWrapper} ${globalStyle.flexWrapperBetween}`}>
                            <div>
                                <BackButton onClick={()=>navigate(routeConfig[0].path)} text="ga terug"/>
                            </div>
                            <div>
                                <Button disabled={filledIn ? false : true} onClick={()=>handleDone()} text="Benoem jouw Grote Mens"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${globalStyle.modal} ${popupOpen ? globalStyle.modalIsOpen : ''}`}>
                <div className={globalStyle.modalInner} style={{backgroundColor: optionsColor.filter(e=>e.value === primary_color).map(e=>e.color)[0]}}>
                    <p style={{marginBottom: 20,}}>Ho, stop! Weet je zeker dat je de kaart aan wilt maken? Als je nu verder gaat wordt jouw Grote Mens gedeeld op de website en kun je er niets meer in aanpassen.</p>
                    <div className={globalStyle.modalButtons}>
                        <Button onClick={handleGenerate} text="Ik weet het zeker" color={optionsColor.filter(e=>e.value === primary_color).map(e=>e.color)[0]} />&nbsp;&nbsp;&nbsp;&nbsp;
                        <ButtonAlt onClick={closePopup} text="Oei, nog iets aanpassen" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Step