import React, {useState} from 'react'
import style from './style.module.css'
import { useDispatch } from 'react-redux'

const DirectorSurNameInput = ({ handleChange, handleFontScale, value, focussed, onFocus, onBlur, currentFontSize, minFontSize, maxFontSize }) => {
    const [val, setVal] = useState();
    const dispatch = useDispatch()

    return (
        <div className={style.container}>
            <input 
                type="text" 
                placeholder="Achternaam" 
                onFocus={onFocus}
                onBlur={onBlur}
                defaultValue={value} 
                onChange={(e) => {
                    setVal(e.target.value.trim());
                    dispatch({ type: 'SET_FIELD', field: 'director_surname', payload: e.target.value.trim() })
                }}
            />

        </div>
    )
}

export default DirectorSurNameInput