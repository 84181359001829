import React, {useState} from 'react'
import style from './style.module.css'
import { useDispatch } from 'react-redux'

const ExplainInput = ({ handleChange, handleFontScale, value, focussed, onFocus, onBlur, currentFontSize, minFontSize, maxFontSize }) => {
    const [val, setVal] = useState();
    const dispatch = useDispatch()

    return (
        <div className={style.container}>
            <textarea 
                placeholder="Extra Informatie. Let op! Deze tekst wordt niet online gepubliceerd (maximaal 300 tekens)." 
                defaultValue={value} 
                maxLength={300}
                onChange={(e) => {
                    setVal(e.target.value.trim());
                    dispatch({ type: 'SET_FIELD', field: 'explanation', payload: e.target.value.trim() })
                }}
            />

        </div>
    )
}

export default ExplainInput