import React, { useEffect,  useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../../components/button'
import Preview from '../preview'
import Pagination from '../pagination'
import { optionsColor } from './fieldOptions'
import { useNavigate } from 'react-router-dom'

import globalStyle from '../../style.module.css'

import Uppy from '@uppy/core'

import { downloadFile } from '../../utilities'

const Step = () => {
    const navigate = useNavigate();

    const { forename, content,  primary_color, download, shareUrl } = useSelector(state => state.form)


    const dispatch = useDispatch()



    const uppy = useMemo(() => {
        return new Uppy({
            restrictions: {
                maxNumberOfFiles: 5,
                allowedFileTypes: ['image/jpeg', 'image/png']
            }
        })
    }, [])


    // Number of retailers

    // Update current screen
    useEffect(() => {
        dispatch({ type: 'SET_FIELD', field: 'current_step', payload: 0 })
    }, [dispatch])


    
    useEffect(() => {
        dispatch({ type: 'SET_FIELD', field: 'show_onboarding', payload: false })
    }, [dispatch])
   

    const updateUppy = useCallback(() => {
        return dispatch({ type: 'SET_FIELD', field: 'retailers', payload: uppy.getFiles()})
    }, [dispatch, uppy])

    useEffect(() => {
        uppy.on('files-added', updateUppy)
        uppy.on('file-removed', updateUppy)
    }, [uppy, updateUppy])

 
 
    const gotoDone = () => {
        navigate('/toolkit#middelen')
    }

    
    const handleDownload = () => {
        downloadFile(download, `${forename}-is-een-groot-mens@2x`)
    }


    const handleShare = (link, shareTitle) => {
        let title = shareTitle || 'Deel'
        let w = 460
        let h = 600
        let left = (window.innerWidth.width/2)-(w/2)
        let top = (window.innerWidth.height/2)-(h/2)
        console.log(222)
        window.open(link, title, 'toolbar=no, directories=no, status=no, menubar=no, resizable=1, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
    }


    return (
        <div className={globalStyle.container}>
            <div className={globalStyle.generatorCols}>
                <div className={`${globalStyle.generatorCol} ${globalStyle.generatorColAlt}`}>
                    <div className={`${globalStyle.preview}`}>
                        <Preview />
                    </div>
                </div>
                <div className={globalStyle.generatorCol}>
                    <div className={globalStyle.form}>
                        <div>
                            <Pagination />
                            <div className={globalStyle.content}>
                                <h2 className={globalStyle.title}>Yes! {forename} is nu officieel benoemd tot Groot Mens van 2023!</h2>
                                <p>Tijdens de Week voor de Gezonde Jeugd op 12 juni maken we de lijst van Grote Mensen 2023 bekend. Deel met het werknet in jouw gemeente waarom {forename} zo Groot is!</p>
                            </div>
                            <div className={globalStyle.contentExtra}>
                            We hebben meer Grote Mensen nodig! Want gezond wordt pas gewoon als genoeg mensen zich inzetten voor een gezonde omgeving. {forename} zet zich vol enthousiasme in voor een gezondere leefomgeving van kinderen. Daarom is {forename} een van de Grote Mensen van 2023!
                            </div>
                            <div className={`${globalStyle.flexWrapper} ${globalStyle.flexWrapperBetween}`}>
                                <div>
                                
                                </div>
                                <button onClick={handleDownload} className={`${globalStyle.btn} ${globalStyle.btnDownload}`}>
                                    <span>Kaart downloaden</span>
                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21.0006 13.0382V17.9171C21.0003 18.0084 20.9892 18.0993 20.9676 18.1877C20.9221 18.4236 20.8007 18.6358 20.6237 18.7888C20.4466 18.9417 20.2249 19.026 19.9956 19.0275H0.999071C0.754686 19.0266 0.519171 18.9313 0.337459 18.7598C0.155747 18.5882 0.0405592 18.3525 0.0138708 18.0975C0.00265369 18.0356 -0.00178457 17.9726 0.000645196 17.9096V13.0308C0.0013929 12.7549 0.106129 12.4906 0.291968 12.2955C0.477807 12.1005 0.729643 11.9905 0.992458 11.9898C1.25502 11.9906 1.5066 12.1004 1.6924 12.2951C1.87814 12.4904 1.98273 12.7548 1.98333 13.0308V16.938H19.0255V13.0308C19.0263 12.7549 19.131 12.4906 19.3168 12.2955C19.5027 12.1005 19.7545 11.9905 20.0173 11.9898C20.2802 11.9904 20.5322 12.1002 20.7182 12.2951C20.8978 12.4969 20.9986 12.7621 21.0006 13.0382ZM9.6609 13.2672L9.68736 13.295C9.86011 13.4787 10.0853 13.5981 10.3287 13.6351C10.3486 13.6351 10.3684 13.642 10.3882 13.642L10.5006 13.6489C10.5404 13.6501 10.5802 13.6478 10.6197 13.642C10.6395 13.642 10.6593 13.6351 10.6792 13.6351C10.9221 13.5966 11.1469 13.4775 11.3205 13.295C11.3272 13.2881 11.3404 13.2811 11.347 13.2672L16.2201 8.1524C16.4343 7.92354 16.5559 7.61661 16.5594 7.29582C16.5629 6.97504 16.4481 6.66525 16.239 6.43127C16.0208 6.20447 15.7262 6.07724 15.4191 6.07724C15.112 6.07724 14.8174 6.20447 14.5992 6.43127L11.671 9.50473L11.6698 2.18713C11.671 2.02734 11.6418 1.86891 11.5839 1.72113C11.526 1.57335 11.4405 1.4392 11.3325 1.32656C11.1134 1.10128 10.8194 0.974342 10.5126 0.972619C10.2062 0.974182 9.91276 1.10264 9.69608 1.33007C9.4794 1.5575 9.35701 1.8655 9.35552 2.18713L9.35675 9.50572L6.42854 6.43226C6.21037 6.20546 5.91571 6.07823 5.60864 6.07823C5.30157 6.07823 5.00692 6.20546 4.78874 6.43226C4.68136 6.54536 4.59629 6.6796 4.53842 6.82727C4.48056 6.97494 4.45103 7.13315 4.45152 7.29283C4.45103 7.4525 4.48056 7.61071 4.53842 7.75838C4.59629 7.90606 4.68136 8.04029 4.78874 8.15339L9.6609 13.2672Z" fill="white"/>
                                    </svg>
                                    
                                </button>
                            </div>
                            <div className={globalStyle.flexWrapper}>
                                <div></div>
                                <div>
                                    <a href={"https://meergrotemensen.nl/toolkit"} target="_blank" className={`${globalStyle.button}`}>
                                        <span className={globalStyle.text}>naar de toolkit</span>
                                        <span className={`${globalStyle.icon}`}>
                                            <svg width="17" height="17" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 17"><path fill="#91C662" stroke="#91C662" d="M8.388.83 7.066 2.152l5.232 5.24H.888v1.876h11.41l-5.241 5.231 1.331 1.331 7.5-7.5-7.5-7.5Z"/></svg>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

      

        </div>
    )
}

export default Step