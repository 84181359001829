import React from 'react'
import globalStyle from '../../style.module.css'

const Button = ({color = '#F58349', children, disabled = false, onClick, text="", full, hideMobileIcon = null}) => {
    return (
        <button className={`${globalStyle.button} ${hideMobileIcon ? globalStyle.hideBtnIcon : ''} ${disabled ? globalStyle.disableBtn : ''}`} onClick={onClick} style={{color: '#91c662', width: full ? '100%' : ''}}>
            <span className={globalStyle.text}>{text}</span>
            <span className={`${globalStyle.icon}`}>
                <svg width="17" height="17" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 17"><path fill="#91C662" stroke="#91C662" d="M8.388.83 7.066 2.152l5.232 5.24H.888v1.876h11.41l-5.241 5.231 1.331 1.331 7.5-7.5-7.5-7.5Z"/></svg>
            </span>
        </button>
    )    
}

export default Button