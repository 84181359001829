import React, {useState} from 'react'
import style from './style.module.css'
import { useDispatch } from 'react-redux'

const ImageInput = ({ handleChange, handleFontScale, value, focussed, onFocus, onBlur, currentFontSize, minFontSize, maxFontSize }) => {
    const [val, setVal] = useState();
    const [file, setFile] = useState(null);
    const dispatch = useDispatch()

    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    return (
        <div className={style.container}>
            <input 
                type="file" 
                onFocus={onFocus}
                onBlur={onBlur}
                defaultValue={file} 
                accept="image/png, image/jpeg"  
                onChange={async (e) => {
                    const { files } = e.target;
                    if (!files || !files[0]) {
                    return;
                    }
                    const selectedFile = files[0];
                    setFile(selectedFile);
                    let imageBase64 = await toBase64(selectedFile);
                    setVal(e.target.value.trim());

                    dispatch({ type: 'SET_FIELD', field: 'avatar_image', payload: imageBase64 })
                    dispatch({ type: 'SET_FIELD', field: 'avatar_extension', payload: selectedFile.type.split('/').pop() })
                }}
            />
            <p style={{fontSize: '14px', marginBottom: 0}}>Plaats een vierkante foto in PNG of JPG (max. 8MB) </p>
        </div>
    )
}

export default ImageInput