import React from 'react'
import globalStyle from '../../style.module.css'

const BackButton = ({color = '#F58349', children, disabled = false, onClick, text="", full, hideMobileIcon = null}) => {
    return (
        <button className={globalStyle.backBtn} onClick={onClick} style={{color: '#91c662', width: full ? '100%' : ''}}>
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.35742 16.457L9.6793 15.1352L4.44805 9.89453L15.8574 9.89453L15.8574 8.01953L4.44805 8.01953L9.68867 2.78828L8.35742 1.45703L0.857422 8.95703L8.35742 16.457Z" fill="white" stroke="white"/></svg>
            <span className={globalStyle.text}>{text}</span>
        </button>
    )    
}

export default BackButton