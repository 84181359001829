import React from 'react'
import PaginationDesktop from './desktop'

const Pagination = ({filledIn}) => {

    return (
        <PaginationDesktop filledIn={filledIn}/>
    )
}

export default Pagination


